import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`V1.2`}</p>
    </blockquote>
    <h2>{`Introduction`}</h2>
    <p>{`The CoreMedia Service Disaster Recovery and Communication Plan is a document that provides an overview of the CoreMedia service and its main features, as well as the procedures and best practices that are in place to minimize the impact of service disruptions on customers' digital channels.`}</p>
    <p>{`This document also provides information on how CoreMedia's teams respond to different types of issues and work to restore service as quickly as possible, including information on data center redundancy, backup power, and disaster recovery testing. Additionally, it explains the procedures for communicating with customers during a service disruption, including how and when updates will be provided, and how customers can expect to be informed about the status of the service.`}</p>
    <p>{`In this introduction, you will find an overview of the CoreMedia service, an explanation of the possible impact of CoreMedia service on customers' digital channels, and an explanation of how problems are classified and prioritized based on their severity.`}</p>
    <p>{`The document also includes a section on `}{`"`}{`Customer Prevention Setup`}{`"`}{` which focuses on providing information and best practices for customers to prevent service disruptions. This includes information on loading the CoreMedia TAG locally, best practices for TAG load management, and how to check if the TAG is loaded locally.`}</p>
    <p>{`We recommend that customers review this document and keep it handy in case of a service disruption. In case of service disruption, please follow the procedures and best practices outlined in this document to minimize the impact on your digital channels.`}</p>
    <h3>{`Overview of CoreMedia service`}</h3>
    <p>{`CoreMedia is a Lead Activation company focused on complex Assisted Sales in Digital channels to create growth and higher customer lifetime value. CoreMedia provides multiple channels such as Voice (with call me back, Click-to-Call, and CallTracker solutions), Live Chat/Chatbots, Email, SMS, Messenger, Whatsapp, Instagram, Video Calls, Video Broadcast, and Viber to its customers mainly from sectors like Telecom (our biggest vertical), Financial services, retail, and utilities among others.`}</p>
    <p>{`The CoreMedia platform, called `}{`"`}{`CoreMedia Lead Activation Hub`}{`"`}{`, is divided into 4 main solutions: `}{`"`}{`CoreMedia Hyperautomation`}{`"`}{`, our `}{`"`}{`CoreMedia Customer Data Platform (CDP)`}{`"`}{`, `}{`"`}{`CoreMedia Marketing Cloud`}{`"`}{` and `}{`"`}{`CoreMedia Cloud Contact Center`}{`"`}{`.`}</p>
    <p>{`CoreMedia has a huge weight on the digital sales of their customers, making it vital that the service is always available and working properly. In case of service disruption, CoreMedia has a Disaster Recovery Plan and procedures in place to minimize the impact on customers' digital channels and restore service as quickly as possible.`}</p>
    <h3>{`Possible Impact of CoreMedia service on customers' digital channels`}</h3>
    <p>{`Service disruptions can have a significant impact on customers' digital channels and their ability to make digital sales. The following are some examples of the possible impact of a CoreMedia service disruption:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Loss of digital sales`}</strong>{`: If the CoreMedia service is down, customers may not be able to access its services and may not be able to make digital sales. This can result in a significant loss of revenue for the customer.`}</li>
      <li parentName="ul"><strong parentName="li">{`Paid media campaigns`}</strong>{`: If a customer has paid for a media campaign that generates visitors to their website, but the CoreMedia service is down, those visitors may not be able to convert on the digital channels. This means that the customer will have paid for visitors that did not convert, resulting in wasted marketing spend.`}</li>
      <li parentName="ul"><strong parentName="li">{`Operators paid for waiting`}</strong>{`: In case of a service disruption, operators may be paid to wait for chats, click-to-call and inbounds with or without call tracker but without any contacts, resulting in wasted resources and costs.`}</li>
    </ul>
    <p>{`CoreMedia understands the importance of minimizing the impact of service disruptions on customers' digital channels, and has a Disaster Recovery Plan and procedures in place to restore service as quickly as possible. Additionally, the company advise customers to load the CoreMedia tag locally, and have default content prepared, in order to prevent service disruptions, and minimize their impact.`}</p>
    <h3>{`Problem classification and prioritization`}</h3>
    <p>{`When faced with problems that interrupt service, CoreMedia's tech team classifies the problem based on the severity of the issue, and prioritizes the resolution of the problem based on this classification. The following are the three levels of problem classification:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`High`}</strong>{` /`}<span style={{
          "color": "red"
        }}>{` `}<strong parentName="li">{`Red`}</strong></span>{`: meaning that the service will be down for at least 2 hours. Every 2 hours the tech team will update status. In this scenario all customers on the affected zone will be notified on each update.`}</li>
      <li parentName="ul"><strong parentName="li">{`Medium`}</strong>{` / `}<span style={{
          "color": "orange"
        }}>{` `}<strong parentName="li">{`Orange`}</strong>{` `}</span>{`: meaning the tech team expects the problem to be solved in 1 hour. Every 1 hours the tech team will update status. In this scenario all customers on the affected zone will be notified on each update.`}</li>
      <li parentName="ul"><strong parentName="li">{`Low`}</strong>{` / `}<span style={{
          "color": "yellow"
        }}><strong parentName="li">{`Yellow`}</strong></span>{`: meaning the tech team expects the problem to be solved in 15 to 30 minutes. Every 30 minutes the tech team will update status. In this scenario all customers on the affected zone will be notified on each update.`}</li>
    </ul>
    <p>{`This problem classification and prioritization system allows CoreMedia's tech team to quickly identify and respond to problems, and minimize their impact on customers' digital channels.`}</p>
    <h2>{`Disaster Recovery Plan`}</h2>
    <p>{`The Disaster Recovery Plan outlines the procedures and best practices that CoreMedia has in place to minimize the impact of service disruptions on customers' digital channels, and restore service as quickly as possible. The following are the key elements of the Disaster Recovery Plan:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Data center redundancy and backup power`}</strong>{`: CoreMedia's service is distributed by 5 main geographical zones, each heavily dependent on specific data centers. In the case that a Data center fails (e.g. failure on electrical power), CoreMedia service may be halve comprised without many redundant possibilities. CoreMedia's data centers are designed to provide redundancy and backup power to minimize the impact of service disruptions.`}</li>
      <li parentName="ul"><strong parentName="li">{`Disaster recovery testing procedures`}</strong>{`: CoreMedia regularly tests its Disaster Recovery Plan to ensure that it is effective and that the procedures and best practices are up-to-date. The testing includes simulations of different types of service disruptions, such as power outages and network failures, to ensure that the Disaster Recovery Plan can be implemented quickly and effectively.`}</li>
      <li parentName="ul"><strong parentName="li">{`Response procedure`}</strong>{`s: CoreMedia's teams have detailed procedures in place for responding to different types of service disruptions. These procedures include steps for identifying the problem, assessing the impact of the problem, and taking the necessary steps to restore service.`}</li>
      <li parentName="ul"><strong parentName="li">{`Service restoration procedures`}</strong>{`: Once a problem has been identified and assessed, CoreMedia's teams work to restore service as quickly as possible. This may include implementing a backup plan, redirecting traffic to a different data center, or taking other steps to minimize the impact of the service disruption.`}</li>
    </ul>
    <p>{`CoreMedia's Disaster Recovery Plan is designed to ensure that service disruptions are minimized, and that service is restored as quickly as possible. CoreMedia has a team dedicated to monitoring the service and responding to issues, and customers can also check the service monitoring on their service Backoffice. Additionally, a Hotline is available 24/7 to be activated whenever there is a problem. Before activating the phone hot line, customers should first use the CoreMedia trouble ticketing service to describe, as detailed as possible, the issue. This will help our teams on better understand the issue, and follow established procedures.`}</p>
    <p>{`It is important to note that CoreMedia's service relies on the availability of third-party services such as internet service providers and cloud providers. In the event of a service disruption caused by a third-party service, CoreMedia will work with the third-party service provider to restore service as quickly as possible.`}</p>
    <p>{`In summary, CoreMedia's Disaster Recovery Plan includes procedures for identifying and responding to service disruptions, restoring service as quickly as possible, and communicating with customers during a service disruption. CoreMedia's team is dedicated to monitoring the service and responding to issues, and regularly testing its Disaster Recovery Plan to ensure that it is effective.`}</p>
    <h3>{`Data center redundancy and backup power`}</h3>
    <p>{`CoreMedia's service is distributed by 5 main geographical zones, each heavily dependent on specific data centers. To minimize the impact of service disruptions caused by data center failures, CoreMedia's data centers are designed to provide redundancy and backup power.`}</p>
    <p>{`Redundancy is achieved by having multiple servers and network components in each data center, so that if one component fails, another can take over. This ensures that the service can continue to operate even if one component fails.`}</p>
    <p>{`Backup power is provided by on-site generators and battery backup systems provided by our datacenter hosting providers, which can take over in case of power outages. This ensures that the data center can continue to operate and provide service to customers even if the primary power source is unavailable.`}</p>
    <p>{`CoreMedia's data centers are also designed with security and compliance in mind, and are regularly audited to ensure that they meet industry standards for data security and privacy.`}</p>
    <p>{`It's important to keep in mind that, as previously stated, CoreMedia's service relies on the availability of third-party services such as internet service providers and cloud providers and in case of a failure on them, the service may be impacted.`}</p>
    <h3>{`Disaster recovery testing procedures`}</h3>
    <p>{`Regular testing of the Disaster Recovery Plan is crucial to ensure that it is effective and that the procedures and best practices are up-to-date. CoreMedia conducts regular testing of its Disaster Recovery Plan, including simulations of different types of service disruptions, such as power outages and network failures.`}</p>
    <p>{`The testing procedures include:`}</p>
    <ul>
      <li parentName="ul">{`Identification of potential scenarios`}</li>
      <li parentName="ul">{`Simulation of the scenarios`}</li>
      <li parentName="ul">{`Evaluation of the response of the system to the scenario`}</li>
      <li parentName="ul">{`Identification of the areas that need improvement`}</li>
      <li parentName="ul">{`Implementation of the improvements`}</li>
    </ul>
    <p>{`These tests are designed to ensure that CoreMedia's teams are prepared to respond quickly and effectively to different types of service disruptions, and that the Disaster Recovery Plan can be implemented quickly and effectively. The tests also help to identify any areas where the Disaster Recovery Plan can be improved, and the necessary changes are made to the plan.`}</p>
    <p>{`Regular testing of the Disaster Recovery Plan is critical to ensure that it is effective and that the procedures and best practices are up-to-date. It also allows CoreMedia to identify and address any vulnerabilities in the system, and to ensure that the service is always available and working properly.`}</p>
    <h3>{`Response procedures for different types of issues`}</h3>
    <p>{`CoreMedia's teams have detailed procedures in place for responding to different types of service disruptions. These procedures include steps for identifying the problem, assessing the impact of the problem, and taking the necessary steps to restore service.`}</p>
    <p>{`The following are the general steps that CoreMedia's teams take when responding to a service disruption:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Identification`}</strong>{`: The first step is to identify the problem and determine what caused the service disruption.`}</li>
      <li parentName="ul"><strong parentName="li">{`Impact assessment`}</strong>{`: Once the problem has been identified, the next step is to assess the impact of the problem on customers' digital channels, and determine how widespread the issue is.`}</li>
      <li parentName="ul"><strong parentName="li">{`Problem solving`}</strong>{`: After the impact has been assessed, the teams will work to solve the problem as quickly as possible.`}</li>
      <li parentName="ul"><strong parentName="li">{`Service restoration`}</strong>{`: Once the problem has been resolved, the teams will take the necessary steps to restore service to customers.`}</li>
    </ul>
    <p>{`CoreMedia's teams are trained to respond quickly and effectively to different types of service disruptions, and are prepared to take the necessary steps to minimize the impact of the disruption on customers' digital channels and restore service as quickly as possible.`}</p>
    <p>{`It's important to note that, as previously stated, CoreMedia's service relies on the availability of third-party services such as internet service providers and cloud providers, and in case of a failure on them, the service may be impacted. In these cases, CoreMedia will work with the third-party service provider to restore service as quickly as possible.`}</p>
    <h3>{`Service restoration procedures`}</h3>
    <p>{`Once a problem has been identified and assessed, CoreMedia's teams work to restore service as quickly as possible. The following are the general steps that are taken to restore service:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Activating backup systems`}</strong>{`: If necessary, CoreMedia will activate backup systems, such as redirecting traffic to a different data center, to minimize the impact of the service disruption.`}</li>
      <li parentName="ul"><strong parentName="li">{`Communication with customers`}</strong>{`: CoreMedia will communicate with customers to keep them informed of the progress of the service restoration, and provide updates on when service is expected to be restored.`}</li>
      <li parentName="ul"><strong parentName="li">{`Monitor the service`}</strong>{`: The teams will monitor the service to ensure that it is working properly, and that there are no further issues.`}</li>
    </ul>
    <p>{`CoreMedia's service restoration procedures are designed to minimize the impact of service disruptions on customers' digital channels, and restore service as quickly as possible. The teams are trained to respond quickly and effectively to different types of service disruptions, and are prepared to take the necessary steps to minimize the impact of the disruption and restore service as quickly as possible. Additionally, CoreMedia's service is designed to allow for the usage of backup systems and communication with customers during service restoration.`}</p>
    <h2>{`Communication and Support`}</h2>
    <p>{`Effective communication and support is critical during service disruptions. CoreMedia has a number of mechanisms in place to ensure that customers are informed of service disruptions, and that they are provided with the support they need during and after a service disruption.`}</p>
    <p>{`The following are the key elements of CoreMedia's communication and support strategy:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Service monitoring and notifications`}</strong>{`: CoreMedia has active monitoring in place to detect and notify of any service disruptions. Customers can also check service monitoring on their service Backoffice.`}</li>
      <li parentName="ul"><strong parentName="li">{`Hotline`}</strong>{`: CoreMedia has a hotline available 24/7 that customers can activate whenever there is a problem. Before activating the phone hot line, customers should first use the CoreMedia trouble ticketing service to describe, as detailed as possible, the issue. This will help our teams on better understand the issue, and follow established procedures.`}</li>
      <li parentName="ul"><strong parentName="li">{`Status updates`}</strong>{`: CoreMedia will provide regular status updates on service disruptions, including the cause of the disruption, the estimated time for service restoration, and any other relevant information. These updates will be made available on the website and through the customer's service Backoffice.`}</li>
      <li parentName="ul"><strong parentName="li">{`Support`}</strong>{`: CoreMedia's support team is available to assist customers during and after a service disruption. Customers can contact the support team for assistance, and for any questions or concerns related to the service disruption.`}</li>
    </ul>
    <p>{`By ensuring effective communication and support during service disruptions, CoreMedia is able to minimize the impact of the disruption on customers' digital channels, and provide the support that customers need to restore service as quickly as possible.`}</p>
    <h3>{`Hotline and trouble-ticketing service`}</h3>
    <p>{`CoreMedia has a hotline available 24/7 that customers can activate whenever there is a problem. The Hotline is an effective way for customers to quickly get in touch with CoreMedia's teams and report any issues they may be experiencing with the service. The OnCall numbers are:`}</p>
    <ul>
      <li parentName="ul">{`+55 (11) 395 604 48 (Brasil);`}</li>
      <li parentName="ul">{`+34 911 338 932 (Espanha);`}</li>
      <li parentName="ul">{`+62 (21) 509 966 55 (Ásia);`}</li>
      <li parentName="ul">{`+351 212 420 708 (PT and rest of the world)`}</li>
    </ul>
    <p>{`Before activating the phone hot line, customers should first use the CoreMedia trouble-ticketing service to describe, as detailed as possible, the issue. This will help our teams on better understand the issue, and follow established procedures.`}</p>
    <p>{`The trouble-ticketing service is an online system for creating, managing, and tracking customer support issues. This allows customers to create a detailed report of the problem, including screenshots and other relevant information. The trouble-ticketing service also allows customers to track the progress of their support request, and receive updates on when the issue will be resolved.`}</p>
    <p>{`By providing a hotline and trouble-ticketing service, CoreMedia is able to ensure that customers can quickly and easily report issues, and that the appropriate teams can respond quickly and effectively to the issue. This helps to minimize the impact of service disruptions on customers' digital channels, and ensures that service is restored as quickly as possible. Customers can also reach out by email to `}<a parentName="p" {...{
        "href": "mailto:help@byside.com"
      }}>{`help@byside.com`}</a>{` for assistance.`}</p>
    <h3>{`Monitoring and status updates for customers`}</h3>
    <p>{`CoreMedia has active monitoring in place to detect and notify of any service disruptions. Customers can also check service monitoring on their service Backoffice, this way they have real-time access to the status of the service and any possible issues.`}</p>
    <p>{`In case of a service disruption, CoreMedia will provide regular status updates on the situation, including the cause of the disruption, the estimated time for service restoration, and any other relevant information. These updates will be made available on the website, through the customer's service Backoffice and through the communication channels previously stated.`}</p>
    <p>{`Customers can also subscribe to receive notifications via email or SMS, this way, they will be updated even if they are not logged in to the Backoffice. This allows customers to stay informed about the status of the service and any issues that may be impacting their digital channels.`}</p>
    <p>{`By providing monitoring and status updates, CoreMedia is able to ensure that customers are informed about any service disruptions and the steps being taken to restore service. This helps to minimize the impact of service disruptions on customers' digital channels, and ensures that service is restored as quickly as possible.`}</p>
    <h3>{`Contact information for CoreMedia support teams`}</h3>
    <p>{`CoreMedia's support teams are available to assist customers during and after a service disruption. Customers can contact the support teams for assistance, and for any questions or concerns related to the service disruption.`}</p>
    <p>{`Customers can reach out to the CoreMedia support teams in the following ways:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Hotline`}</strong>{`: Customers can call,  for immediate assistance, the hotline numbers (24/7):`}
        <ul parentName="li">
          <li parentName="ul">{`+55 (11) 395 604 48 (Brasil):`}</li>
          <li parentName="ul">{`+34 911 338 932 (Espanha);`}</li>
          <li parentName="ul">{`+62 (21) 509 966 55 (Ásia);`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`+351 212 420 708`}</strong>{` (PT and reset of the world)`}</li>
      <li parentName="ul"><strong parentName="li">{`Email`}</strong>{`: Customers can email the support team at `}<a parentName="li" {...{
          "href": "mailto:help@byside.com"
        }}>{`help@byside.com`}</a>{` for assistance.`}</li>
      <li parentName="ul"><strong parentName="li">{`Trouble-ticketing service`}</strong>{`: Customers can use the online trouble-ticketing service to report and track issues, and receive updates on when the issue will be resolved.`}</li>
    </ul>
    <p>{`By providing multiple channels of contact, CoreMedia's support teams are able to ensure that customers can reach out for assistance in the way that is most convenient for them. This helps to minimize the impact of service disruptions on customers' digital channels, and ensures that service is restored as quickly as possible.`}</p>
    <h3>{`Communication procedures during service disruptions`}</h3>
    <p>{`Effective communication is critical during service disruptions. CoreMedia has established procedures in place to ensure that customers are informed of service disruptions, and that they are provided with the support they need during and after a service disruption.`}</p>
    <p>{`The following are the key elements of CoreMedia's communication procedures during service disruptions:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Initial notification`}</strong>{`: CoreMedia will immediately notify customers of any service disruptions, including the cause of the disruption, the estimated time for service restoration, and any other relevant information, through the hotline and the trouble-ticketing service.`}</li>
      <li parentName="ul"><strong parentName="li">{`Status updates`}</strong>{`: CoreMedia will provide regular status updates on service disruptions, including the cause of the disruption, the estimated time for service restoration, and any other relevant information. These updates will be made available on the website, through the customer's service Backoffice and through the communication channels previously stated.`}</li>
      <li parentName="ul"><strong parentName="li">{`Escalation`}</strong>{`: In case of an extended service disruption, CoreMedia will escalate the issue to the appropriate teams, and provide updates on the progress of the resolution.`}</li>
      <li parentName="ul"><strong parentName="li">{`Service restoration`}</strong>{`: Once service is restored, CoreMedia will notify customers and confirm that the service is fully operational.`}</li>
    </ul>
    <p>{`By providing effective communication procedures, CoreMedia is able to ensure that customers are informed about any service disruptions and the steps being taken to restore service. This helps to minimize the impact of service disruptions on customers' digital channels, and ensures that service is restored as quickly as possible.`}</p>
    <h2>{`Customer Prevention Setup`}</h2>
    <p>{`The goal of this chapter is to provide customers with the information and tools they need to minimize the impact of service disruptions on their digital channels. By implementing the recommendations outlined in this chapter, customers can take proactive measures to ensure that their digital channels remain operational, even in the event of a service disruption.`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Local load of CoreMedia TAG`}</strong>{`: To minimize the impact of service disruptions on customers' websites, it is recommended that the CoreMedia TAG is loaded locally from customers' websites. If the CoreMedia service is down, loading the TAG from our servers may cause the website to load slowly or not at all. By loading the TAG locally, customers can ensure that their website remains operational even if the CoreMedia service is down.`}</li>
      <li parentName="ol"><strong parentName="li">{`Default Content Management:`}</strong>{` Inline campaigns managed by CoreMedia service have the ability to load default content when the service is down. It's advisable that customers prepare default content for all the inline campaigns. This will ensure that visitors to the website still see relevant content, even if the CoreMedia service is down.`}</li>
      <li parentName="ol"><strong parentName="li">{`Monitoring and alerting`}</strong>{`: CoreMedia customers can set up monitoring and alerting for their digital channels, this way they can be notified of any issues, and take action to resolve them as quickly as possible.`}</li>
    </ol>
    <p>{`By implementing these recommendations, customers can take proactive measures to ensure that their digital channels remain operational, even in the event of a service disruption. This will help to minimize the impact of service disruptions on customers' digital channels, and ensure that service is restored as quickly as possible.`}</p>
    <h3>{`How to load the CoreMedia TAG locally`}</h3>
    <p>{`To load the CoreMedia TAG locally on your website, you can follow these steps:`}</p>
    <ol>
      <li parentName="ol">{`Download the CoreMedia TAG from the CoreMedia service Backoffice or by requesting it from the support team.`}</li>
      <li parentName="ol">{`Place the downloaded TAG in your website's codebase. It's recommended to place the TAG in the header section of the website, so it can be loaded as soon as possible.`}</li>
      <li parentName="ol">{`Update the website's code to load the TAG from the local source instead of from CoreMedia's servers.`}</li>
      <li parentName="ol">{`Test the website to ensure that the TAG is loaded correctly and that the website remains operational even if the CoreMedia service is down.`}</li>
    </ol>
    <p>{`It's important to note that, if you are using Tag Manager, you may need to configure it to load the tag locally instead of remotely.`}</p>
    <p>{`By loading the CoreMedia TAG locally, customers can ensure that their website remains operational even if the CoreMedia service is down. This will help to minimize the impact of service disruptions on customers' digital channels and ensure that service is restored as quickly as possible.`}</p>
    <h3>{`About CoreMedia TAG`}</h3>
    <p>{`The CoreMedia TAG is a small piece of JavaScript code that is placed on a website in order to enable the CoreMedia Lead Activation service. Once the tag is placed on the website, it enables the integration of multiple digital channels, such as voice, live chat/chatbots, email, SMS, messenger, WhatsApp, Instagram, video calls, video broadcast, and Viber.`}</p>
    <p>{`The CoreMedia TAG allows customers to create and manage digital campaigns that target specific visitors to their website. These campaigns can be used to increase conversions, generate leads and revenue, and improve customer lifetime value.`}</p>
    <p>{`The CoreMedia TAG is designed to be lightweight and fast, so it won't slow down the loading of the website. It is also designed to be cacheable, which means that it can be stored in the browser's cache, reducing the load time on subsequent visits.`}</p>
    <p>{`The CoreMedia TAG is an important component of the CoreMedia Lead Activation service, and it is necessary for the service to function correctly. Customers are advised to load the tag locally in order to minimize the impact of service disruptions on their digital channels.`}</p>
    <h3>{`Best practices for TAG load management`}</h3>
    <p>{`To ensure that the CoreMedia TAG is loaded correctly and that your website remains operational even if the CoreMedia service is down, it is important to follow best practices for TAG load management.`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Optimize the load order`}</strong>{`: It's important to ensure that the CoreMedia TAG is loaded as early as possible in the page load process. This will ensure that the tag is fully loaded and ready to function before any other elements on the page.`}</li>
      <li parentName="ol"><strong parentName="li">{`Use async or defer attributes`}</strong>{`: Adding the async or defer attribute to the CoreMedia TAG code will ensure that the tag is loaded asynchronously, which means that it will not block the loading of other elements on the page. This will improve the overall page load speed and user experience.`}</li>
      <li parentName="ol"><strong parentName="li">{`Use a Content Delivery Network (CDN)`}</strong>{`: By using a CDN to host the CoreMedia TAG, you can ensure that the tag is served to users from a location that is closest to them. This will reduce the time it takes for the tag to load and improve the overall user experience.`}</li>
      <li parentName="ol"><strong parentName="li">{`Monitor and troubleshoot`}</strong>{`: Regularly monitoring the load time and performance of the CoreMedia TAG is important to ensure that it is functioning correctly. If you notice any issues, you should troubleshoot and resolve them as quickly as possible to minimize the impact on your website.`}</li>
    </ol>
    <p>{`By following these best practices, customers can ensure that the CoreMedia TAG is loaded correctly and that their website remains operational even if the CoreMedia service is down. This will help to minimize the impact of service disruptions on customers' digital channels and ensure that service is restored as quickly as possible.`}</p>
    <h3>{`How to check if the CoreMedia TAG is loaded locally`}</h3>
    <p>{`To check if the CoreMedia TAG is loaded locally on your website, you can use the browser's developer tools or a website performance monitoring tool.`}</p>
    <ol>
      <li parentName="ol">{`Open the website in a browser, and right-click on the webpage. Select `}{`"`}{`Inspect`}{`"`}{` or `}{`"`}{`Inspect Element`}{`"`}{` from the context menu.`}</li>
      <li parentName="ol">{`In the developer tools, navigate to the `}{`"`}{`Network`}{`"`}{` tab.`}</li>
      <li parentName="ol">{`Reload the webpage and check the `}{`"`}{`Initiator`}{`"`}{` column in the network tab. If the CoreMedia TAG is loaded locally, it should show the file path of the local file (e.g. `}{`"`}{`index.html`}{`"`}{` or `}{`"`}{`localhost`}{`"`}{`). If the CoreMedia TAG is loaded remotely, it should show the URL of the remote file (e.g. `}{`"`}<a parentName="li" {...{
          "href": "https://www.byside.com/tag.js"
        }}>{`https://www.byside.com/tag.js`}</a>{`"`}{`).`}</li>
      <li parentName="ol">{`You can also use website performance monitoring tools that track the load time and performance of the website and its resources, including the CoreMedia TAG. These tools will show you the origin of the resources loaded on the website, including the CoreMedia TAG.`}</li>
    </ol>
    <p>{`By checking if the CoreMedia TAG is loaded locally, customers can ensure that their website remains operational even if the CoreMedia service is down. This will help to minimize the impact of service disruptions on customers' digital channels and ensure that service is restored as quickly as possible.`}</p>
    <h2>{`Conclusion and Additional Information`}</h2>
    <p>{`The goal of this document is to provide customers with the information and procedures they need to minimize the impact of service disruptions on their digital channels. By following the recommendations outlined in this document, customers can take proactive measures to ensure that their digital channels remain operational, even in the event of a service disruption.`}</p>
    <p>{`In this document, we have covered the following topics:`}</p>
    <ul>
      <li parentName="ul">{`Overview of the CoreMedia service and its capabilities`}</li>
      <li parentName="ul">{`Possible impact of service disruptions on customers' digital channels`}</li>
      <li parentName="ul">{`Problem classification and prioritization`}</li>
      <li parentName="ul">{`Disaster recovery plan, including data center redundancy and backup power, disaster recovery testing procedures, response procedures for different types of issues, and service restoration procedures`}</li>
      <li parentName="ul">{`Communication and support, including hotline and trouble-ticketing service, monitoring and status updates for customers, contact information for CoreMedia support teams, and communication procedures during service disruptions`}</li>
      <li parentName="ul">{`Customer Prevention Setup, including how to load the CoreMedia TAG locally, best practices for TAG load management, and how to check if the TAG is loaded locally`}</li>
    </ul>
    <p>{`In conclusion, CoreMedia is committed to providing the highest level of service to our customers and we have put in place measures to minimize the impact of service disruptions on their digital channels. However, it is important for customers to take proactive measures to ensure that their digital channels remain operational even in the event of a service disruption. By following the recommendations outlined in this document, customers can minimize the impact of service disruptions on their digital channels and ensure that service is restored as quickly as possible.`}</p>
    <p>{`It's important to notice that this document should be used as a guide, not as a substitute for professional advice or judgement. This document may be reviewed and updated as necessary to meet the changing requirements of CoreMedia service.`}</p>
    <h3>{`How customers can access and monitor their service status`}</h3>
    <p>{`Customers can access and monitor their service status in the CoreMedia service Backoffice. The Backoffice provides real-time information about the performance and availability of the service, including any issues that may be affecting the service.`}</p>
    <p>{`To access the service status:`}</p>
    <ol>
      <li parentName="ol">{`Log in to the CoreMedia service Backoffice using the credentials provided by CoreMedia.`}</li>
      <li parentName="ol">{`Navigate to the `}{`"`}{`Monitoring`}{`"`}{` or `}{`"`}{`Status`}{`"`}{` page.`}</li>
      <li parentName="ol">{`The page will display real-time information about the performance and availability of the service, including any issues that may be affecting the service.`}</li>
      <li parentName="ol">{`Customers can also set up notifications to be alerted about any issues that may affect their service.`}</li>
    </ol>
    <p>{`By monitoring their service status, customers can stay informed about the performance and availability of the service and take appropriate actions to minimize the impact of any issues that may arise.`}</p>
    <p>{`Customers may also use third-party monitoring tools or services that can check and alert the customer of CoreMedia's service status.`}</p>
    <h3>{`Additional resources and information for customers`}</h3>
    <p>{`In addition to this Disaster Recovery Plan, customers may find the following resources and information helpful:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`CoreMedia service documentation`}</strong>{`: Detailed documentation on the features and capabilities of the CoreMedia service, as well as how to set up and use the service.`}</li>
      <li parentName="ul"><strong parentName="li">{`CoreMedia service knowledge base`}</strong>{`: A collection of articles and tutorials on various topics related to the CoreMedia service, such as best practices, troubleshooting tips, and FAQs.`}</li>
      <li parentName="ul"><strong parentName="li">{`CoreMedia service support`}</strong>{`: A dedicated team of support professionals available to assist customers with any questions or issues they may have.`}</li>
      <li parentName="ul"><strong parentName="li">{`CoreMedia service blog (tbd)`}</strong>{`: Regularly updated articles and posts on topics related to the CoreMedia service and digital sales.`}</li>
    </ul>
    <p>{`By taking advantage of these additional resources, customers can gain a deeper understanding of the CoreMedia service and how to use it effectively to achieve their digital sales goals.`}</p>
    <h3>{`Customer's role in case of service disruption`}</h3>
    <p>{`In the event of a service disruption, customers play an important role in minimizing the impact on their digital channels and ensuring that service is restored as quickly as possible. The following are some of the steps customers should take in case of a service disruption:`}</p>
    <ul>
      <li parentName="ul">{`Follow the instructions provided in the Disaster Recovery Plan.`}</li>
      <li parentName="ul">{`Check the CoreMedia service status and monitor it regularly.`}</li>
      <li parentName="ul">{`Report the problem to CoreMedia by opening a trouble ticket or contacting the hotline.`}</li>
      <li parentName="ul">{`Follow the instructions and updates provided by CoreMedia support team.`}</li>
      <li parentName="ul">{`Implement the customer prevention setup, such as loading the CoreMedia TAG locally and preparing default content for campaigns.`}</li>
      <li parentName="ul">{`Take note of any additional information or instructions provided by CoreMedia support team.`}</li>
    </ul>
    <p>{`By following these steps, customers can minimize the impact of service disruptions on their digital channels and ensure that service is restored as quickly as possible.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      